/* eslint-disable max-len */
import React from 'react';
import Helmet from 'react-helmet';
import wrapInSection from '../components/wrapInSection';
import wrapInLayout from '../components/wrapInLayout';
import Image from '../components/Image';

const LinguoType = () => (
  <div className="landing">
    <Helmet title="КЭСПА – Лингвотип" />
    <div className="linguotype-wrapper page-wrapper narrow-container">
      <h1>Лингвотип</h1>

      <p>
        Мы выделили два принципиально отличающихся друг от друга способа
        вербализации на любом языке:
      </p>

      <ul>
        <li>говорить просто и мало</li>
        <li>говорить красиво</li>
      </ul>

      <p>
        Следовательно, можно выделить два типа людей по их отношению к процессу
        говорения. Того,&nbsp;кто говорит просто и мало, мы назвали{' '}
        <span className="emphasized">
          «минималистом в выборе речевых средств» («речевым минималистом»)
        </span>
        , так как ему нужны минимум слов и минимум грамматических конструкций
        для выражения своих мыслей – эти мысли, естественно, могут быть
        абсолютно гениальны и красивы.
      </p>

      <p>
        Того, кому для выражения своих мыслей и эмоций нужно использовать язык
        по максимуму (много эпитетов, сравнений, идиом, сложных грамматических
        конструкций), мы назвали{' '}
        <span className="emphasized">
          «максималистом в выборе речевых средств» («речевым максималистом»)
        </span>
        .
      </p>

      <Image
        src="linguotypes.jpg"
        className="img-70-desktop"
        alt="Максималист и минималист в естественной среде обитания"
      />

      <p>
        Так как у нас появился такой замечательный критерий классификации людей,
        как «выбор&nbsp;речевых средств для выражения своих мыслей и эмоций», мы
        смело можем ввести новое понятие –{' '}
        <span className="emphasized">прагмалингвистический тип</span>{' '}
        (сокращенно <span className="emphasized">лингвотип</span>).
      </p>

      <p>
        Просим не путать с психотипом, о котором все, конечно, слышали. На
        всякий случай уточняем: психотип (психологический тип) – характеристика
        человека с точки зрения психологии. А&nbsp;лингвотип
        (прагмалингвистический тип) – это характеристика человека с точки зрения
        прагмалингвистики. Прагмалингвистика (она же «лингвистическая
        прагматика») – область языкознания, изучающая речь.
      </p>

      <p>
        Психологических школ несколько, и в каждой – свои критерии классификации
        личностей. Взяв&nbsp;за основу особенности нервной системы и физической
        конституции, Гиппократ выделил четыре&nbsp;психотипа:
      </p>
      <ul>
        <li>сангвиник (сильный, уравновешенный, подвижный)</li>
        <li>холерик (сильный, неуравновешенный)</li>
        <li>флегматик (сильный, уравновешенный, инертный)</li>
        <li>меланхолик (слабый, неуравновешенный, инертный)</li>
      </ul>

      <p>
        Карл Юнг предложил другой критерий классификации – расположенность к
        общению с&nbsp;внешним миром, и предложил делить людей на экстравертов
        (расположены к общению, ориентированы во вне) и интровертов (не склонны
        к общению, ориентированы во внутрь).
      </p>

      <p>
        Психотип не меняется в течение всей жизни человека. А лингвотип может
        меняться в&nbsp;зависимости от конкретной жизненной ситуации и по мере
        освоения нового иностранного&nbsp;языка.
      </p>

      <h2>Лингвотип и КЭСПА</h2>

      <p>
        Каким же образом нам в школе КЭСПА удается учитывать те личностные
        особенности людей, которые существенно влияют на их способность овладеть{' '}
        <span className="emphasized">чужим</span> языком в условиях{' '}
        <span className="emphasized">родной</span> языковой среды?
      </p>

      <p>
        Начиная работать с учеником , КЭСПА-преподаватель прежде всего “пишет”
        его лингвопортрет, то есть определяет его лингвотип и его{' '}
        <span className="emphasized">коммуникативные привычки</span>.
      </p>

      <p>
        У каждого человека есть свои коммуникативные привычки: кто-то говорит
        очень быстро, глотая окончания слов и ожесточенно жестикулируя; кто-то
        говорит невнятно и тихо; у кого-то через каждые два слова вылетают
        привычные речевые сорняки («понимаешь», «как бы», «ну, как это» и т.д.).
      </p>

      <p>
        Чтобы процесс обучения происходил максимально эффективно,
        КЭСПА-преподаватель оценивает ученика по следующим параметрам:
      </p>

      <ol className="marked">
        <li>скорость речи на родном языке;</li>
        <li>
          желание общаться, то есть являетесь ли вы «инициатором речи» (сами
          затеваете разговор) или «вынужденным собеседником» (предпочитаете
          отвечать на вопросы, причем кратко);
        </li>
        <li>
          желание или нежелание быть услышанным (из-за боязни возможной ошибки),
          которое порождает невнятное бормотание, как на родном, так и на чужом
          языке.
        </li>
      </ol>

      <p>
        Принимая во внимание лингвотип ученика, КЭСПА-преподаватель составляет
        или подбирает для него{' '}
        <span className="emphasized">актуальные тексты</span>. Актуальный текст
        – это текст, составленный для определенного лингвотипа с определенным
        набором грамматики, актуальным на данный момент изучения языка. Учитывая
        коммуникативные привычки ученика, КЭСПА-преподаватель выбирает способ
        работы с актуальными текстами.
      </p>

      <p>
        Разговорчивых, тараторящих, громогласных, молчаливых, обстоятельных и
        невнятно бормочущих себе под нос людей надо учить по-разному :)
      </p>
    </div>
  </div>
);

export default wrapInLayout(wrapInSection(LinguoType));
